import {
  FaBarcode,
  FaCalendarDay,
  FaDotCircle,
  FaRegDotCircle,
} from "react-icons/fa";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { CiImageOn } from "react-icons/ci";
import { IoDocumentAttach, IoDocumentAttachOutline } from "react-icons/io5";

import { BorderCard, ButtonImage, ImageTitle, StrongItem } from "./styles";
import { manifestStatus } from "../../../../../Shared/Utils/constants";
import { IoIosArrowForward } from "react-icons/io";
import { EmptyTableMessage } from "../../../../../Shared/Components/EmptyTableMessage";
import { BiError } from "react-icons/bi";

const ItemCard = ({ item }) => {
  return (
    <Col className="d-flex align-items-center gap-1">
      {item.icon}
      <span>{item.label}:</span>
      <StrongItem
        className={
          item.status === "4"
            ? "text-danger"
            : item.status === "2" && "text-success"
        }
      >
        {item.value}
      </StrongItem>
    </Col>
  );
};

export const ManifestEventsCard = ({ cte, handleSelectImage }) => {
  return (
    <BorderCard>
      <Row xs={1} md={2} lg={3} className="mb-2 d-flex g-2">
        <ItemCard
          item={{
            icon: <FaDotCircle size={12} />,
            label: "Número CTE",
            value: cte.conhec_numero,
          }}
        />
        <ItemCard
          item={{
            icon: (
              <FaRegDotCircle
                size={12}
                color={cte.conhec_ctestatus === 4 ? "red" : "green"}
              />
            ),
            label: "Status",
            value: manifestStatus(cte.conhec_ctestatus),
            status: cte.conhec_ctestatus,
          }}
        />
        <ItemCard
          item={{
            icon: <FaCalendarDay size={12} />,
            label: "Data CT-e",
            value: moment.utc(cte.conhec_data).format("DD/MM/YYYY HH:mm"),
          }}
        />
      </Row>
      <ItemCard
        item={{
          icon: <FaBarcode size={12} />,
          label: "Chave CT-e",
          value: cte.conhec_ctechave,
        }}
      />
      <div className="d-flex align-items-center gap-1 mb-3 mt-3">
        {cte.conhec_docs_anexados.length === 0 ? (
          <IoDocumentAttachOutline />
        ) : (
          <IoDocumentAttach />
        )}
        <ImageTitle>Documentos anexados:</ImageTitle>
      </div>
      {Array.isArray(cte.conhec_docs_anexados) &&
        cte.conhec_docs_anexados.length === 0 && (
          <div>
            <EmptyTableMessage
              icon={<BiError />}
              label="Sem documento anexado"
            />
          </div>
        )}
      {Array.isArray(cte.conhec_docs_anexados) &&
        cte.conhec_docs_anexados.length > 0 && (
          <Row xs={1} md={2} lg={3} xl={4} className="d-flex g-2">
            {cte.conhec_docs_anexados.map((doc) => (
              <Col key={doc.image}>
                <ButtonImage
                  className="w-100 d-flex align-items-center justify-content-between p-2"
                  onClick={() => handleSelectImage(doc?.image)}
                >
                  <CiImageOn size={62} />
                  <span className="fw-bold">
                    Ver mais <IoIosArrowForward />
                  </span>
                </ButtonImage>
              </Col>
            ))}
          </Row>
        )}
    </BorderCard>
  );
};
