import { useState } from "react";
import { useForm } from "react-hook-form";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";

import { IoFilterSharp } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

import { FilterContent, SearchContainer } from "./styles";
import { SubmitButton } from "../../../../Shared/Components/SubmitButton/SubmitButton";
import ButtonLink from "../../../../Shared/Components/ButtonLink/ButtonLink";

const manifestStatus = [
  { id: "invalid_status", value: "-- Selecione o status --" },
  { id: "2", value: "Autorizado" },
  { id: "4", value: "Cancelado" },
  { id: "7", value: "Encerrado" },
];

export const SearchCTEList = ({ handleGetList }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { register, handleSubmit, reset, watch } = useForm();
  const watchFields = watch();

  const handleClearFilter = () => {
    reset();
    setIsOpen(false);
    handleGetList();
  };

  const onSubmit = (data) => {
    handleGetList(data);
    setIsOpen(false);
  };

  const isFilterNotEmpty = Object.values(watchFields).some(
    (value) => !!value && value !== "invalid_status"
  );

  return (
    <div className="mb-1">
      <div className="d-flex justify-content-end mb-2 align-items-center gap-2">
        <SearchContainer
          onClick={() => setIsOpen(!isOpen)}
          title="Abrir filtro"
        >
          <span>Filtrar</span>
          <IoFilterSharp color="#FFF" />
        </SearchContainer>
        {isFilterNotEmpty && (
          <ButtonLink
            label="Limpar filtro"
            fontSize="0.875rem"
            buttoncolor="gray"
            handleClick={handleClearFilter}
            icon={<IoMdClose size={14} />}
          />
        )}
      </div>

      <Modal show={isOpen} onHide={handleClearFilter}>
        <Modal.Header closeButton>
          <Modal.Title>Filtrar</Modal.Title>
        </Modal.Header>
        <FilterContent className="p-3">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="d-flex g-2" sm={1} md={2}>
              <Form.Group as={Col} xs={12} md={6} controlId="formManifestDate">
                <FloatingLabel controlId="formManifestDate" label="Início">
                  <Form.Control
                    {...register("data_inicio")}
                    size="sm"
                    type="date"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} controlId="formManifestDate">
                <FloatingLabel controlId="formManifestDate" label="Final">
                  <Form.Control
                    {...register("data_fim")}
                    size="sm"
                    type="date"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} controlId="forManifestCode">
                <FloatingLabel
                  controlId="forManifestCode"
                  label="Cód. Manifesto"
                >
                  <Form.Control
                    {...register("manif_cod")}
                    size="sm"
                    type="text"
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} controlId="formCteStatus">
                <FloatingLabel controlId="formCteStatus" label="Status MDFe">
                  <Form.Select
                    aria-label="Default select example"
                    {...register("manif_status_mdfe")}
                  >
                    {manifestStatus.map((status) => (
                      <option value={status?.id} key={status?.id}>
                        {status?.value}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} controlId="formDriverName">
                <FloatingLabel
                  controlId="formDriverName"
                  label="Nome motorista"
                >
                  <Form.Control
                    {...register("mot_nome")}
                    size="sm"
                    type="text"
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} controlId="formDriverCPF">
                <FloatingLabel controlId="formDriverCPF" label="CPF motorista">
                  <Form.Control
                    {...register("mot_cpf")}
                    size="sm"
                    type="text"
                  />
                </FloatingLabel>
              </Form.Group>
            </Row>
            <div className="d-flex align-items-center justify-content-end gap-1 mt-2">
              <SubmitButton
                labelButton="Aplicar filtro"
                icon={<FaCheck size="0.7rem" />}
              />
              <ButtonLink
                label="Limpar filtro"
                fontSize="0.7rem"
                buttoncolor="gray"
                handleClick={handleClearFilter}
                icon={<IoMdClose size="0.7rem" />}
              />
            </div>
          </Form>
        </FilterContent>
      </Modal>
    </div>
  );
};
