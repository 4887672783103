import React, { useRef, useEffect, useState } from "react";
import {
  BtnsContainer,
  CloseCameraButton,
  FileUploadContainer,
  ModalContent,
  ModalWrapper,
  TakePictureButton,
  WebcamContainer,
} from "./styles";
import { TbCameraPlus } from "react-icons/tb";
import { FaCamera } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

const Camera = ({ handlePhotoChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [photo, setPhoto] = useState(null);

  const handleOpenCamera = () => {
    setIsOpen(true);
    navigator.mediaDevices
      .getUserMedia({
        video: {
          width: 1920,
          height: 1080,
          advanced: [{ facingMode: "environment" }],
          zoom: { ideal: 1 },
        },
      })
      .then((stream) => {
        const video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.error("Erro ao acessar a câmera: ", err);
      });
  };

  const handleCloseCamera = () => {
    videoRef.current.srcObject.getTracks().forEach(function (track) {
      track.stop();
      setIsOpen(false);
    });
  };

  useEffect(() => {}, []);

  const takePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    const scaleFactor = 2;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext("2d");

    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const photoData = canvas.toDataURL("image/png");
    // console.log("photoData", photoData);
    setPhoto(photoData);
    handlePhotoChange(photoData);
    handleCloseCamera();
  };

  return (
    <div className="mb-3 w-100">
      <div>
        <FileUploadContainer onClick={handleOpenCamera} className="w-100">
          <label className="custom-file-upload text-center">
            <div className="d-flex justify-content-center">
              <TbCameraPlus size={64} />
            </div>
            Abrir câmera
          </label>
        </FileUploadContainer>

        {isOpen && (
          <ModalWrapper>
            <ModalContent>
              <WebcamContainer>
                <video ref={videoRef} autoPlay playsInline></video>
                {/* <button onClick={takePhoto}>Tirar foto</button> */}
                <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
                {/* {photo && <img src={photo} alt="Foto tirada" />} */}

                <BtnsContainer>
                  <div className="d-flex align-items-end gap-2 justify-content-center gap-5">
                    <div className="d-flex flex-column align-items-center">
                      <TakePictureButton onClick={takePhoto}>
                        <FaCamera />
                      </TakePictureButton>
                      <span className="text-white">Capturar</span>
                    </div>

                    <CloseCameraButton>
                      <button onClick={handleCloseCamera}>
                        <IoClose />
                      </button>
                      <span className="text-white">Fechar</span>
                    </CloseCameraButton>
                  </div>
                </BtnsContainer>
              </WebcamContainer>
            </ModalContent>
          </ModalWrapper>
        )}
      </div>
    </div>
  );
};

export default Camera;
