import styled from "styled-components";

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 300px;
  > img {
    height: 100%;
  }
`;

export const SliderContainer = styled.div`
  height: 340px;
  width: 95vw;
  margin: 10px auto;

  @media (min-width: 800px) {
    width: 30vw;
  }
`;
