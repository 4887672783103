import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { FaArrowLeft, FaArrowRight, FaCheckCircle } from "react-icons/fa";
import { IoWarningOutline } from "react-icons/io5";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { Col, Container, Image, Row } from "react-bootstrap";

import { BorderCard, CardList, RemoveButton, Thumbnail } from "./styles";
import "./components/Scanner/styles";
import { Button } from "./components/Button";
import { EmptyArea } from "./components/EmptyArea";
import { PageTitle } from "./components/PageTitle";
import { MultiSteps } from "./components/MultiSteps";
import { ProgressCard } from "./components/ProgressCard";
import { WebcamCapture } from "./components/WebcamCapture";
import FileUpload from "./components/FileUpload/FileUpload";

import pdfThumbnail from "../../Assets/Images/pdfIcon.png";
import { useManifest } from "../../hooks/ManifestiesProvider";
import { Loading } from "../../Shared/Components/Loading/Loading";
import { Header } from "./components/Header/Header";
import { WebcamScanner } from "./components/WebcamScanner";
import { ErrorMessageCard } from "./components/ErrorMessageCard";
import ReceiptSelectorSlider from "./components/ReceiptTypesSlider";
import { CiImageOn } from "react-icons/ci";
import { TbCameraPlus } from "react-icons/tb";
import Camera from "./components/Camera";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

const MAX_FILENAME_LENGTH = 9;

let PDFJS;

(async function () {
  PDFJS = await import("pdfjs-dist/build/pdf");
  const pdfjsWorker = await import("pdfjs-dist/build/pdf.worker.entry");
  PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;
})();

const BarcodeScanner = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [selectedReceiptModel, setSelectedReceiptModel] = useState("");
  const [fileLoading, setFileLoading] = useState(false);

  const [result, setResult] = useState(null);
  const [files, setFiles] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [verifiedDocs, setVerifiedDocs] = useState([]);
  const [successLabel, setSuccessLabel] = useState(
    "Leitura do QR code concluída!"
  );
  const [scanResult, setScanResult] = useState("");

  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const hashValue = params.get("hash");

  const {
    hashManifest,
    isLoading,
    hasError,
    verifyBarcodeError,
    geHashManifest,
    postHasManifest,
    getVerifyBarcode,
    postManifestValidation,
    setVerifyBarcodeError,
    getVerifyNumberVehicle,
    postValidateManifestImage,
    errorValidateImage,
    setErrorValidateImage,
    isVerifyImgLoading,
  } = useManifest();

  useEffect(() => {
    geHashManifest(hashValue);
  }, [hashValue, geHashManifest]);

  const onVerifyInput = async (numero_cte, placa, errorResult) => {
    if (errorResult) {
      setActiveStep(1);
    } else {
      const status = await getVerifyNumberVehicle(
        hashManifest?.manif_cod,
        numero_cte,
        placa
      );
      if (
        status?.success &&
        (!status?.conhec_docs_anexados ||
          status?.conhec_docs_anexados.length === 0)
      ) {
        setResult(status.conhec_ctechave);
      } else {
        setActiveStep(1);
        setResult(null);
      }
    }
  };

  const onDetected = async (barcode) => {
    const status = await getVerifyBarcode(hashManifest?.manif_cod, barcode);

    if (
      status?.success &&
      (!status?.conhec_docs_anexados ||
        status?.conhec_docs_anexados.length === 0)
    ) {
      setResult(barcode);
    } else {
      setActiveStep(1);
      setResult(null);
    }
  };

  const handleCancelScanner = (setErrorResult) => {
    setResult(null);
    setErrorResult(false);
    setVerifyBarcodeError(null);
  };

  // Funções para o componente FileUpload

  const handleRemoveFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);

    if (photos.length === 0 && newFiles.length === 0) {
      setActiveStep(2);
    }
  };

  const renderThumbnail = (file) => {
    if (file.type === "application/pdf") {
      return <Thumbnail src={pdfThumbnail} alt={file.name} />;
    } else {
      return <Thumbnail src={URL.createObjectURL(file)} alt={file.name} />;
    }
  };

  const formatFileName = (name) => {
    if (name.length > MAX_FILENAME_LENGTH) {
      const firstPart = name.substring(0, MAX_FILENAME_LENGTH / 2);
      const secondPart = name.substring(name.length - MAX_FILENAME_LENGTH / 2);
      return `${firstPart}...${secondPart}`;
    } else {
      return name;
    }
  };

  const handleFileChange = async (e) => {
    setErrorValidateImage(null);
    const selectedFile = e.target.files[0];

    const file = await changeFiles(selectedFile);

    setPhotos([file]);
    handleSendImages(null, file);
  };

  const handlePhotoChange = async (photo) => {
    setPhotos([...photos, photo]);
    setErrorValidateImage(null);
    handleSendImages(photo);
  };

  const handleDeletePhoto = (index) => {
    const updatedPhotos = [...verifiedDocs];
    updatedPhotos.splice(index, 1);
    setVerifiedDocs(updatedPhotos);

    if (updatedPhotos.length === 0 && files.length === 0) {
      setActiveStep(2);
      setSelectedReceiptModel("");
      setSelectedSlide(0);
      setPhotos([]);
      setFiles([]);
    }
  };

  const handleSendImages = async (photo, file) => {
    if (!photo && !file) {
      return alert("Insira pelo menos uma imagem");
    }
    if (photo || file) {
      // setActiveStep(3);
      let body = {
        image: photo ? photo : file,
      };

      body.image = body.image?.split("base64,")[1];
      const receipType =
        selectedSlide === 0
          ? "bertolini"
          : selectedSlide === 1
          ? "ldc"
          : "rumo";

      const res = await postValidateManifestImage(
        body,
        selectedReceiptModel,
        setActiveStep
      );

      if (res === "pass") {
        if (photo) setVerifiedDocs([...verifiedDocs, photo]);
        if (file) setVerifiedDocs([...verifiedDocs, file]);
        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          html: `
            <div>
              <h3>O comprovante foi anexado, deseja adicionar outro documento?
              </h3>
            </div>
          `,
          showCancelButton: true,
          cancelButtonColor: "#6c757d",
          cancelButtonText: "Não, Concluir.",
          confirmButtonColor: "#0071c7",
          confirmButtonText: "Sim!",
          focusCancel: true,
          preConfirm: async () => {
            setPhotos([]);
            setFiles([]);
            setSelectedReceiptModel("");
            setSelectedSlide(0);
            setActiveStep(2);
          },
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading(),
          didOpen: (toast) => {
            toast.style.zIndex = "99999";
          },
        });
      }
    }
  };

  const changeFiles = useCallback((event) => {
    return new Promise((resolve, reject) => {
      try {
        setFileLoading(true);
        setErrorValidateImage("");
        const selectedFile = event;

        const reader = new FileReader();

        reader.onload = (e) => {
          if (selectedFile.type === "application/pdf") {
            const fileReader = new FileReader();
            fileReader.onload = async (e) => {
              const pdfData = new Uint8Array(e.target.result);
              const pdf = await PDFJS.getDocument(pdfData).promise;

              const page = await pdf.getPage(1);
              const viewport = page.getViewport({ scale: 5 });
              const canvas = document.createElement("canvas");
              canvas.width = viewport.width;
              canvas.height = viewport.height;
              const canvasContext = canvas.getContext("2d");
              const renderContext = {
                canvasContext,
                viewport,
              };
              await page.render(renderContext).promise;

              const imageBase64 = canvas.toDataURL("image/png", 1);

              resolve(imageBase64);
            };

            fileReader.readAsArrayBuffer(selectedFile);
          } else if (
            selectedFile.type === "image/jpeg" ||
            selectedFile.type === "image/png"
          ) {
            const arrayBuffer = e.target.result;
            const base64 = btoa(
              new Uint8Array(arrayBuffer).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );
            const imageBase64 =
              "data:" + selectedFile.type + ";base64," + base64;

            resolve(imageBase64);
          } else {
            reject("Tipo de arquivo não suportado: " + selectedFile.type);
          }
        };

        reader.readAsArrayBuffer(selectedFile);
      } catch (error) {
        console.error("Error reading file:", error);
        setErrorValidateImage("Erro ao ler arquivo.");
      } finally {
        setFileLoading(false);
      }
    });
  }, []);

  const handleSend = async () => {
    if (verifiedDocs.length === 0) {
      return alert("Insira pelo menos uma imagem");
    }

    const postData = [...verifiedDocs];

    const documents = postData.map((item) => {
      return {
        image: item,
      };
    });

    await postHasManifest(
      result,
      {
        documentos: documents,
        conhec_hash_motorista: hashValue,
      },
      setActiveStep
    );

    setFiles([]);
    setPhotos([]);
    setVerifiedDocs([]);
    setSelectedReceiptModel("");
  };

  const handleCancelSendImages = () => {
    geHashManifest(hashValue);
    setFiles([]);
    setPhotos([]);
    setActiveStep(2);
    setSelectedReceiptModel("");
    setErrorValidateImage("");
  };

  const progressIndicator = useMemo(() => {
    if (hashManifest.conhecimentos && hashManifest.conhecimentos.length > 0) {
      const index = hashManifest.conhecimentos.filter(
        (manifest) => manifest.conhec_docs_anexados.length === 0
      ).length;

      return (
        100 *
        ((hashManifest.conhecimentos.length - index) /
          hashManifest.conhecimentos.length)
      );
    }
    return 0;
  }, [hashManifest.conhecimentos]);

  useEffect(() => {
    if (
      progressIndicator === 100 &&
      hashManifest?.manif_status_mdfe &&
      (hashManifest.manif_status_mdfe === "6" ||
        hashManifest.manif_status_mdfe === "7" ||
        hashManifest.manif_status_mdfe !== "8")
    ) {
      setActiveStep(5);
    }
  }, [progressIndicator, hashManifest]);

  useEffect(() => {
    if (errorValidateImage && errorValidateImage !== "") {
      setPhotos([]);
      setFiles([]);
    }
  }, [errorValidateImage]);

  return (
    <div className="min-vh-100 d-flex flex-column p-3">
      {isLoading && (
        <>
          <Loading />
        </>
      )}
      {!isLoading && hasError && (
        <div>
          <Header
            progressIndicator={progressIndicator}
            hashManifest={hashManifest}
          />
          <div className="mt-5">
            <EmptyArea />
          </div>
        </div>
      )}

      {!isLoading &&
        !hasError &&
        Array.isArray(hashManifest.conhecimentos) &&
        hashManifest?.conhecimentos?.length > 0 && (
          <div>
            <Header
              progressIndicator={progressIndicator}
              hashManifest={hashManifest}
            />
            <MultiSteps size={5} currentStep={activeStep} />
            {activeStep === 1 && (
              <Container className="mt-2">
                {progressIndicator === 100 ? (
                  <ProgressCard
                    progress={progressIndicator}
                    hashManifest={hashManifest.conhecimentos}
                    width={progressIndicator < 100 ? 100 : 200}
                    height={progressIndicator < 100 ? 100 : 200}
                  />
                ) : (
                  <WebcamScanner
                    setActiveStep={setActiveStep}
                    postManifestValidation={postManifestValidation}
                    onDetected={onDetected}
                    onVerifyInput={onVerifyInput}
                    verifyBarcodeError={verifyBarcodeError}
                    handleCancelScanner={handleCancelScanner}
                    setVerifyBarcodeError={setVerifyBarcodeError}
                    successLabel={successLabel}
                    setSuccessLabel={setSuccessLabel}
                    result={result}
                    setScanResult={setScanResult}
                  />
                )}
              </Container>
            )}

            {activeStep === 2 && (
              <div>
                <PageTitle label="Selecione o modelo do comprovante" />
                {errorValidateImage &&
                  photos.length === 0 &&
                  files.length === 0 && (
                    <ErrorMessageCard label={errorValidateImage} />
                  )}
                <div
                  className="d-flex justify-content-center align-items-center gap-1"
                  style={
                    selectedReceiptModel !== ""
                      ? {
                          color: "var(--color-green)",
                        }
                      : {}
                  }
                >
                  {selectedReceiptModel !== "" && (
                    <IoMdCheckmarkCircleOutline size={30} />
                  )}
                  <p className="fs-4 fw-bold m-0">
                    {selectedSlide === 0
                      ? "BERTOLINI"
                      : selectedSlide === 1
                      ? "LDC"
                      : selectedSlide === 2
                      ? "RUMO"
                      : selectedSlide === 3
                      ? "ADM"
                      : selectedSlide === 4
                      ? "TICKET PESAGEM TERMINAL DE GRÃOS PONTA DA MONTANHA"
                      : selectedSlide === 5
                      ? "LAZAROTTO"
                      : selectedSlide === 6
                      ? "CANHOTO NOTA FISCAL"
                      : selectedSlide === 7
                      ? "CTE RODOVIVA"
                      : selectedSlide === 8
                      ? "DNI NAVEGAÇÃO"
                      : selectedSlide === 9
                      ? "RODOVIVA"
                      : "MOVIMENTAÇÃO DE CARGA"}
                  </p>
                </div>

                <ReceiptSelectorSlider
                  setIndex={setSelectedSlide}
                  index={selectedSlide}
                  selectedReceiptModel={selectedReceiptModel}
                />

                <div className="d-flex flex-column justify-content-center mt-3 gap-3">
                  <div className="d-flex justify-content-center gap-3 w-100">
                    {selectedReceiptModel === "" ? (
                      <Button
                        backgroundColor="#093c5e"
                        border="#093c5e"
                        color="white"
                        label="Selecionar modelo"
                        onClick={() =>
                          setSelectedReceiptModel(
                            selectedSlide === 0
                              ? "bertolini"
                              : selectedSlide === 1
                              ? "ldc"
                              : selectedSlide === 2
                              ? "rumo"
                              : selectedSlide === 3
                              ? "adm"
                              : selectedSlide === 4
                              ? "ticket_pesagem"
                              : selectedSlide === 5
                              ? "lazarotto"
                              : selectedSlide === 6
                              ? "canhoto"
                              : selectedSlide === 7
                              ? "cte_rodoviva"
                              : selectedSlide === 8
                              ? "navegacao"
                              : selectedSlide === 9
                              ? "rodoviva"
                              : "movimentacao_carga"
                          )
                        }
                        style={{
                          maxWidth: "290px",
                          paddingBlock: "15px",
                          fontSize: "1.3rem",
                        }}
                      />
                    ) : (
                      <Button
                        backgroundColor="white"
                        border="#093c5e"
                        color="#093c5e"
                        label="Selecionar outro modelo"
                        onClick={() => {
                          setSelectedReceiptModel("");
                        }}
                        style={{
                          maxWidth: "290px",
                          paddingBlock: "15px",
                          fontSize: "1.3rem",
                        }}
                      />
                    )}
                  </div>
                  <div className="d-flex justify-content-center gap-3 w-100">
                    <Button
                      backgroundColor="white"
                      border="#093c5e"
                      color="#093c5e"
                      label="Voltar"
                      leftIcon={<FaArrowLeft />}
                      onClick={() => {
                        setSelectedReceiptModel("");
                        setActiveStep(1);
                      }}
                      style={{
                        maxWidth: "150px",
                        paddingBlock: "15px",
                        fontSize: "1.3rem",
                      }}
                    />

                    <Button
                      backgroundColor="#093c5e"
                      border="#093c5e"
                      color="white"
                      label="Seguir"
                      disabled={selectedReceiptModel === ""}
                      rightIcon={<FaArrowRight />}
                      onClick={() => setActiveStep(3)}
                      style={{
                        maxWidth: "150px",
                        paddingBlock: "15px",
                        fontSize: "1.3rem",
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {activeStep === 3 && (
              <div>
                <PageTitle label="Adicione os comprovantes de descarga" />
                {errorValidateImage &&
                  photos.length === 0 &&
                  files.length === 0 && (
                    <ErrorMessageCard label={errorValidateImage} />
                  )}
                {fileLoading || isVerifyImgLoading ? (
                  <div className="d-flex align-items-center justify-content-center gap-3">
                    <div>
                      <Loading />
                    </div>
                    <span className="fw-semibold fs-5">
                      Aguarde, analisando imagem...
                    </span>
                  </div>
                ) : (
                  <>
                    <Row xs={1} className="d-flex g-3">
                      <Col>
                        {photos.length === 0 && files.length === 0 && (
                          <FileUpload handleFileChange={handleFileChange} />
                        )}
                      </Col>
                      <Col>
                        {photos.length === 0 && files.length === 0 && (
                          <Camera handlePhotoChange={handlePhotoChange} />
                        )}
                      </Col>
                    </Row>

                    <div className="p-2">
                      <div className="d-flex flex-column gap-2">
                        {photos.map((photo, index) => (
                          <CardList
                            key={index}
                            className="d-flex align-items-center justify-content-between"
                          >
                            <Image
                              width={60}
                              height={60}
                              src={photo}
                              rounded
                              alt={`Documento ${index}`}
                            />
                          </CardList>
                        ))}
                      </div>
                    </div>

                    <div className="d-flex justify-content-center mt-3 ">
                      <div className="d-flex justify-content-center gap-3 w-100">
                        <Button
                          backgroundColor="white"
                          border="#093c5e"
                          color="#093c5e"
                          label="Voltar"
                          leftIcon={<FaArrowLeft />}
                          onClick={handleCancelSendImages}
                          style={{
                            maxWidth: "150px",
                            paddingBlock: "15px",
                            fontSize: "1.3rem",
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}

            {activeStep === 4 && (
              <div>
                <PageTitle label="Confirmar documentos" />
                <div className="px-2 mb-3">
                  <span
                    className="fw-semibold"
                    style={{
                      color: "var(--color-green)",
                      fontSize: "0.9rem",
                    }}
                  >
                    O comprovante foi carregado com sucesso!
                  </span>
                </div>
                <div className="p-2">
                  <div className="d-flex flex-column gap-2">
                    {verifiedDocs.map((photo, index) => (
                      <CardList
                        key={index}
                        className="d-flex align-items-center justify-content-between"
                      >
                        <Image
                          width={60}
                          height={60}
                          src={photo}
                          rounded
                          alt={`Documento ${index}`}
                        />
                        <RemoveButton
                          onClick={() => {
                            handleDeletePhoto(index);
                          }}
                          className="d-flex align-items-center gap-2 py-2 px-3"
                          style={{ fontSize: "1.1rem" }}
                        >
                          Remover
                        </RemoveButton>
                      </CardList>
                    ))}
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center mt-2">
                  <div className="d-flex justify-content-center gap-3 w-100">
                    <Button
                      backgroundColor="white"
                      border="#093c5e"
                      color="#093c5e"
                      label="Voltar"
                      leftIcon={<FaArrowLeft />}
                      onClick={() => {
                        setActiveStep(2);
                        setPhotos([]);
                        setFiles([]);
                        setSelectedReceiptModel("");
                        setSelectedSlide(0);
                      }}
                      style={{
                        maxWidth: "150px",
                        paddingBlock: "15px",
                        fontSize: "1.3rem",
                      }}
                    />

                    <Button
                      backgroundColor="#093c5e"
                      border="#093c5e"
                      color="white"
                      label="Confirmar"
                      rightIcon={<FaArrowRight />}
                      onClick={handleSend}
                      style={{
                        maxWidth: "160px",
                        paddingBlock: "15px",
                        fontSize: "1.3rem",
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {activeStep === 5 && (
              <div>
                <div className="mt-2 d-flex flex-column flex-grow-1 w-100 p-3 gap-4">
                  <ProgressCard
                    progress={progressIndicator}
                    hashManifest={hashManifest.conhecimentos}
                  />
                  {progressIndicator < 100 && (
                    <Button
                      label="Enviar novos documentos para outra CT-e"
                      onClick={() => {
                        setActiveStep(1);
                        setResult(null);
                      }}
                      backgroundColor="#093c5e"
                      border="#093c5e"
                      color="white"
                      style={{
                        paddingBlock: "15px",
                        fontSize: "1.2rem",
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        )}

      {!isLoading && hashManifest?.conhecimentos?.length === 0 && (
        <>
          <Header
            progressIndicator={progressIndicator}
            hashManifest={hashManifest}
          />

          <div className="d-flex justify-content-center mt-5">
            <BorderCard>
              <div className="d-flex flex-column gap-3 align-items-center justify-content-center text-center">
                <IoWarningOutline color="#cb1313" size={42} />
                <span>Nenhum conhecimento encontrado para esse MDF-e!</span>
              </div>
            </BorderCard>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(BarcodeScanner);
