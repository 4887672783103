import QrScanner from "qr-scanner";
import { useEffect, useRef, useState } from "react";
import { QrContainer } from "./styles";
import Webcam from "react-webcam";
import { IoScanOutline } from "react-icons/io5";

const QRcodeScanner = ({
  onScanSuccess,
  scanner,
  setIsOpen,
  isOpen,
  onScanFail,
  error,
}) => {
  const video = useRef(null);
  const qrBox = useRef(null);
  const [isQrOn, setIsQrOn] = useState(true);
  // const webcamRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (video?.current?.video && !scanner.current) {
      // Instantiate the QR Scanner
      scanner.current = new QrScanner(video?.current?.video, onScanSuccess, {
        onDecodeError: onScanFail,
        //This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
        preferredCamera: "environment",
        //  This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
        highlightScanRegion: true,
        //  This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
        highlightCodeOutline: true,
        //  A custom div which will pair with "highlightScanRegion" option above. This gives us full control over our scan region.
        overlay: qrBox?.current || undefined,
      });

      // Start QR Scanner
      scanner?.current
        ?.start()
        .then(() => setIsQrOn(true))
        .catch((err) => {
          if (err) setIsQrOn(false);
        });
    }

    setTimeout(() => {
      scanner?.current?.stop();
      setIsOpen(false);
    }, 20000);

    // Clean up on unmount.
    // This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
    // return () => {
    //   if (!video?.current?.video) {
    //     scanner?.current?.stop();
    //     setIsOpen(false);
    //   }
    // };
  }, [isOpen]);

  useEffect(() => {
    if (!isQrOn)
      alert(
        "Câmera bloqueada ou não acessível. Por favor permita que o navegador acesse sua câmera."
      );
  }, [isQrOn]);

  useEffect(() => {
    // console.log("video", video);
    // console.log("webcamRef", webcamRef);
  }, [video]);

  const videoConstraints = {
    facingMode: ["environment"],
  };

  return (
    <QrContainer>
      <Webcam
        audio={false}
        ref={video}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
      />

      <div ref={qrBox} className="qr-box">
        <IoScanOutline
          className="qr-frame"
          size={256}
          color="var(--color-active)"
        />
      </div>

      {error !== "" && <p className="text-danger fw-bold">{error}</p>}
    </QrContainer>
  );
};

export default QRcodeScanner;
